import React, { Component } from 'react';
import './index.css';

class App extends Component {

  constructor(props) {
  	super(props) 

  	this.state = {w:0,h:0,screenFormat: "" };

  	this.screenFormat = this.screenFormat.bind(this);
  }

  componentDidMount() {
  	
  	window.addEventListener("load", this.screenFormat);
  	window.addEventListener("resize", this.screenFormat);
  	
  }

  screenFormat = () => {

  	let ww = window.innerWidth;
  	let wh = window.innerHeight;
  	this.setState({ w: ww, h: wh});

  	if(ww > wh) {
  		this.setState({ screenFormat: "landscape" })
  	} else {
  		this.setState({ screenFormat: "portrait" })
  	}
  	

  }
  render() {

    return (
      <div className={"App App--"+this.state.screenFormat}><a href="MAILTO:office@taniguchi-ambos.com"><div className="App-sections"><div className="App-section  App-section--1"><span>blue</span></div><div className="App-section  App-section--2"><span>red</span></div></div></a></div>
    );

  }
}

export default App;
